// NOTE: This extra component was created to avoid loading the translation deps on google LPs
import React from "react"
import { useTranslation } from "react-i18next"
import useHindiLangFont from "../hooks/useHindiLangFont"
import { BenefitItem, BenefitsContainer } from "./campaigns/ExtraBenefits"
import { Section, SectionWrapper, SubTitle } from "./core/commonExports"

const ExtraBenefits = ({
  titleStyle={},
  desktopStyles={background: "rgba(252,235,227,0.4)"}, 
  mobileStyles={background: "rgba(252,235,227,0.4)"}
}) => {
  const {t} = useTranslation('extraBenefits')
  const {hindiBold} = useHindiLangFont()
  return (
    <Section desktopStyles={desktopStyles} mobileStyles={mobileStyles}>
      <SectionWrapper>
        <SubTitle 
          fontSize="32px" 
          mfontSize="20px" 
          lineHeight="38px"
          mlineHeight="32px" 
          fontWeight="700"
          style={{textAlign: "center", ...titleStyle}} 
          className={hindiBold}
        >
          {t('Title')}
        </SubTitle>
        <BenefitsContainer className={hindiBold}>
          <BenefitItem icon="CashStolen" desc={t('CashStolen')} />
          <BenefitItem icon="LossOfDocs" desc={t('DamageLoss')} />
          <BenefitItem icon="AdditionalCoverPercent" desc={t('UnderInsurance')} />
          <BenefitItem icon="PersonalBelongings" desc={t('PersonalBelongings')} />
        </BenefitsContainer>
      </SectionWrapper>
    </Section>
  )
}

export default ExtraBenefits